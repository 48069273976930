<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="电子账簿名称">
              <el-input v-model="searchForm.eAccountName" placeholder="请输入要搜索的电子账簿名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电子账簿号码">
              <el-input v-model="searchForm.eAccountNumber" placeholder="请输入要搜索的电子账簿号码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属企业">
              <el-input v-model="searchForm.firmName" placeholder="请输入要搜索的所属企业" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账户类型">
              <el-select v-model="searchForm.userType" placeholder="请选择要搜索的账户类型" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.accountUserType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="80">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'accountListDetail', query: { userId: scope.row.userId, firmId: scope.row.firmId, eaccountNumber: scope.row.eaccountNumber } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        eAccountName: '',
        eAccountNumber: '',
        firmName: '',
        userType: ''
      },
      columnOption: [
        { label: '电子账薄名称', prop: 'eaccountName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '电子账薄号码', prop: 'eaccountNumber', formatter: this.utils.isEffective, minWidth: 230 },
        { label: '所属企业', prop: 'firmName', formatter: this.utils.isEffective, minWidth: 160 },
        { label: '账薄类型', prop: 'userType', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'accountUserType'), minWidth: 100 },
      ]
    }
  },
  computed: {},
  methods: {
    getList () {
      this.loading.list = true
      this.api.system.account.tentAllList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        console.log(result)
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style>

</style>
